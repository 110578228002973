




































































import { Component, Prop, Vue } from 'vue-property-decorator'
import CloudUploadIcon from '@/app/ui/assets/cloud_upload_icon.vue'
import DropzoneCloseIcon from '@/app/ui/assets/dropzone_close_icon.vue'
import MultipleImage from '@/app/ui/components/ImageUpload/MultipleImage.vue'
import SingleImage from '@/app/ui/components/ImageUpload/SingleImage.vue'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {
    CloudUploadIcon,
    DropzoneCloseIcon,
    MultipleImage,
    SingleImage
  },
})
export default class ImageUpload extends Vue {
  @Prop({ default: () => [] }) private value!: File[] | string[]
  @Prop({ type: String, default: '' }) private fieldLabel?: string
  @Prop({ type: String }) private accept?: string
  @Prop({ type: Number, default: 100 }) private maxFileSize!: number // in KB
  @Prop({ type: Number, default: 2 }) private maxCount!: number
  @Prop({ type: Boolean, default: true }) private maxDefault!: boolean
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: 'Drop Image' }) private dropText!: string
  @Prop({ type: Boolean, default: false }) private multiple!: boolean
  @Prop({ type: Boolean, default: false }) private required!: boolean
  @Prop({ type: String, default: '' }) private description!: string
  @Prop({ type: String, default: '' }) private errorMessage!: string
  @Prop({ type: String, default: '' }) private subText!: string
  @Prop({ type: Boolean, default: false }) private isError!: boolean

  dragOver = false
  error = ''
  isImageValid = false

  get imagePreview(): File[] | string[] {
    return this.value
  }

  get getText(): string {
    if (this.dragOver) {
      return this.dropText
    }

    return this.text
  }

  private onInputFileChange(
    files: File,
    index: number
  ) {
    this.isImageValid = false
    if (index > 0) {
      this.$emit('on-input', files, index)
    } else {
      try {
        if (files) {
          if (
            this.accept &&
            !this.accept.split(',').includes(files.type)
          ) {
            throw new Error(`File Type must be in ${this.accept}`)
          }

          if (files.size / 1024 > this.maxFileSize) {
            throw new Error(`File size exceeds ${this.maxFileSize}KB`)
          }

          this.isImageValid = true
          this.$emit('on-input', files, index)
        }
      } catch (error) {
        this.$emit('on-error')
        const err = <Error>error
        // Show error alert
        this.$notify({
          title: 'Input Image Failed',
          text: err.message,
          type: 'error',
          duration: 5000,
        })
      }
    }
  }

  private drop({ dataTransfer }: { dataTransfer: DataTransfer }, index: number) {
    this.dragOver = false
    this.onInputFileChange(dataTransfer?.files[0], index)
  }

  private removeImage(index: number) {
    this.isImageValid = false
    this.$emit('on-remove', index)
  }

  private dragover() {
    this.dragOver = true
  }

  private dragleave() {
    this.dragOver = false
  }
}
