
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import CloudUploadIcon from '@/app/ui/assets/cloud_upload_icon.vue'
import DropzoneCloseIcon from '@/app/ui/assets/dropzone_close_icon.vue'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: {
    Button,
    TrashIcon,
    CloudUploadIcon,
    DropzoneCloseIcon,
  },
})

/**
 * maxFileSize: number
 * Max file size in KB
 *
 * text: string
 * Text that is displayed when idle
 *
 * dropText: string
 * Text that is displayed when the file dragged
 *
 */
export default class SingleImage extends Vue {
  @Prop({ type: String }) private accept?: string
  @Prop({ type: Number, default: 100 }) private maxSize!: number // in KB
  @Prop({ type: String, default: 'Upload Image' }) private text!: string
  @Prop({ type: String, default: 'Drop Image' }) private dropText!: string
  @Prop({ type: Number, default: 0 }) private minWidth!: number
  @Prop({ type: Number, default: 0 }) private minHeight!: number
  @Prop({ type: String, default: '' }) private errorMessage!: string
  @Prop({ default: () => [] }) private value!: File[] | string[]

  fileName: string | null = null
  error = ''

  private onInputFileChange(
    event: {
      target: { files: File[]; value: string }
    }
  ) {
    const { files } = event.target
    try {
      if (files) {
        if (
          this.accept &&
          !this.accept.split(',').includes(files[0].type)
        ) {
          throw new Error(`File Type must be in ${this.accept}`)
        }

        if (files[0].size / 1024 > this.maxSize) {
          throw new Error(`File size exceeds ${this.maxSize}KB`)
        }

        this.$emit('on-input', files[0], 0) // 0 it's mean index
        event.target.value = ''
      }
    } catch (error) {
      const err = <Error>error
      // Show error alert
      this.$notify({
        title: 'Input Image Failed',
        text: err.message,
        type: 'error',
        duration: 5000,
      })
      event.target.value = ''
    }
  }

  private removeImage() {
    this.$emit('on-remove', 0) // 0 it's mean index
  }

  private convertToURL(): File | string {
    const file = this.value[0]
    if (file) {
      if ((<File>file).name) {
        const url = URL.createObjectURL(<Blob | MediaSource>this.value[0])
        return url
      } else {
        return file
      }
    } else {
      return ''
    }
  }

  @Watch('errorMessage')
  setErrorMessage(message: string): void {
    this.error = message
  }
}
