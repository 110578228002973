






































import { Component, Prop, Vue } from 'vue-property-decorator'
import AddIcon from '@/app/ui/assets/add_circle.vue'
import TrashIcon from '@/app/ui/assets/trash_icon.vue'

@Component({
  components: {
    AddIcon,
    TrashIcon,
  },
})
export default class MultipleImage extends Vue {
  @Prop({ default: () => [] }) private value!: File[] | string[]
  @Prop({ type: Number, default: 2 }) private maxCount!: number
  @Prop({ type: String, default: 'image/*' }) private accept!: string
  @Prop({ type: Number, default: 10 }) private maxSize!: number // in KB
  @Prop({ type: Boolean, default: false }) private isDisabled!: boolean

  private onFileChange(
    event: {
      target: { files: File[]; value: string }
    },
    index: number
  ) {
    const { files } = event.target
    try {
      if (files) {
        if (
          this.accept &&
          !this.accept.split(',').includes(files[0].type)
        ) {
          throw new Error(`File Type must be in ${this.accept}`)
        }

        if (files[0].size / 1024 > this.maxSize) {
          throw new Error(`File size exceeds ${this.maxSize}KB`)
        }

        this.$emit('on-input', files[0], index)
        event.target.value = ''
      }
    } catch (error) {
      const err = <Error>error
      // Show error alert
      this.$notify({
        title: 'Input Image Failed',
        text: err.message,
        type: 'error',
        duration: 5000,
      })
      event.target.value = ''
    }
  }

  private convertToURL(index: number): File | string {
    const file = this.value[index]
    if (file) {
      if ((<File>file).name) {
        const url = URL.createObjectURL(<Blob | MediaSource>this.value[index])
        return url
      } else {
        return file
      }
    } else {
      return ''
    }
  }

  private onRemoveImage(index: number) {
    this.$emit('on-remove', index)
  }

  private isShowImage(index: number) {
    const file = this.value[index]
    if (file) {
      if ((<File>file).name) {
        return true
      } else if (typeof file === 'string') {
        return true
      }
    } else {
      return false
    }
  }
}
